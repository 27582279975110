import axios from 'axios'
import { MESSAGES } from 'constants/common'
import { destroyCookie, parseCookies } from 'nookies'

export const HttpRequest = (type: any, url: string, data = {}, headers = {}, others = {}) => {
  const BaseUrl =   process.env.REACT_APP_API_HOST_URL || 'https://staging-352717.uk.r.appspot.com/api'
  const { accessToken } = parseCookies()
  let headersData = {}

  if (accessToken) {
    headersData = {
      ...headers,

      Authorization: `${accessToken}`,
    }
  } else {
    headersData = {
      ...headers,
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      method: type,
      responseType: 'json',
      url: BaseUrl + url,
      data,
      params: type === 'get' ? data : '',
      headers: headersData,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if (error?.response?.data?.messageCode === MESSAGES.CODE.TOKEN_INVALID) {
          destroyCookie(null, 'accessToken')
          destroyCookie(null, 'refreshToken')
          window.location.href = '/login'
          return
        }
        if (error?.response?.status === 400) {
          // Need to write function here to fetch the new token with refreshToken if accessToke is expired.
          // window.location.href = '/login'
        }
        reject(error)
      })
  })
}
